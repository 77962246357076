import { render, staticRenderFns } from "./drugbook.vue?vue&type=template&id=c7e87bd4&"
import script from "./drugbook.vue?vue&type=script&lang=js&"
export * from "./drugbook.vue?vue&type=script&lang=js&"
import style0 from "./drugbook.vue?vue&type=style&index=0&id=c7e87bd4&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports