<template>
  <div>
    <vs-table class="hidden sm:block" :maxHeight="maxHeight" ref="table" :sst="true" :total="totalDocs"
      :data="complianceDrugbook" :complianceCheck="isComplianceCheck">
      <!-- v-if="complianceDrugbook.length" -->
      <template slot="thead">
        <vs-th style="background: #bcd6ce" sort-key="clinicName">PRODUCT NAME</vs-th>
        <vs-th style="background: #bcd6ce" sort-key="orderYear">DRUGBOOK BALANCE</vs-th>
        <vs-th style="background: #bcd6ce" sort-key="orderMonth">COUNTED QUANTITY</vs-th>
        <vs-th style="background: #bcd6ce" sort-key="status">VARIANCE</vs-th>
        <vs-th style="background: #bcd6ce" v-if="!isComplianceCheck" sort-key="status">NOTES</vs-th>
        <vs-th style="background: #bcd6ce" v-if="!isComplianceCheck">ACTIONS</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].productName">{{
              data[indextr].productName
          }}</vs-td>
          <vs-td :data="data[indextr].drugBookBalance">{{
              data[indextr].drugBookBalance
          }}</vs-td>
          <vs-td :data="data[indextr].countedQuality">{{
              data[indextr].countedQuality
          }}</vs-td>
          <vs-td :data="data[indextr].variance" style="color: #e02020">
            <p v-if="data[indextr].variance == 0">
              {{
                  `${data[indextr].variance}${data[indextr].unit === "ml" ? data[indextr].unit : "u"
                  }`
              }}
            </p>
            <p v-else>
              {{
                  `${data[indextr].drugBookBalance > data[indextr].countedQuality
                    ? `-${data[indextr].variance}`
                    : `+${Math.abs(data[indextr].variance)}`
                  }${data[indextr].unit === "ml" ? data[indextr].unit : "u"}`
              }}
            </p>
          </vs-td>
          <vs-td v-if="!isComplianceCheck" :data="data[indextr].notes">{{
              data[indextr].notes
          }}</vs-td>
          <vs-td v-if="!isComplianceCheck" :data="data[indextr].productName">
            <vs-button @click="toggleModal(data[indextr])" type="border">Edit</vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="sm:hidden">
      <div class="shadow-md p-2 my-2 rounded-lg" v-for="drugData in complianceDrugbook" :key="drugData._id">
        <div class="flex justify-items-center w-full">
          <div class="w-1/2">
            <div style="color: #0e4333" class="font-semibold text-sm leading-loose">
              {{ drugData.productName }}
            </div>
            <div style="color: #6e6b7b" class="font-medium text-xs">
              Counted Quantity
            </div>
            <div style="color: #6e6b7b" class="font-medium text-xs">
              Drugbook Balance
            </div>
            <div style="color: #e02020" class="font-medium text-xs">
              Variance
            </div>
          </div>
          <div class="w-1/2 text-right">
            <div @click="toggleModal(drugData)" style="color: #0e4333"
              class="w-full font-semibold text-sm leading-loose cursor-auto">
              <vs-icon class="cursor-auto" icon="edit" />
            </div>
            <div style="color: #6e6b7b" class="w-full font-medium text-xs">
              {{ `${drugData.countedQuality || "0"}${"u"}` }}
            </div>
            <div style="color: #6e6b7b" class="w-full font-medium text-xs">
              {{ `${drugData.drugBookBalance || "0"}${"u"}` }}
            </div>
            <div style="color: #e02020" class="w-full font-medium text-xs">
              {{
                  `${drugData.drugBookBalance > drugData.countedQuality
                    ? `-${drugData.variance}`
                    : `+${Math.abs(drugData.variance)}`
                  }${"u"}`
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <vs-popup class="holamundo" title="Edit Record" :active.sync="popupActive" @close="onCancel">
        <div>
          <form autocomplete="off" @submit.prevent>
            <div>
              <div class="w-full my-5">
                <label>Drug</label>
                <vs-input :success="
                  !errors.first('productName') &&
                  drugbookData.productName != ''
                " val-icon-success="done" val-icon-danger="error"
                  :danger="errors.first('productName') ? true : false" v-validate="'required|max:150'"
                  name="productName" data-vv-as="productName" class="w-full" :danger-text="errors.first('productName')"
                  v-model="drugbookData.productName" :disabled="true" />
              </div>
              <div class="w-full my-5">
                <label>Counted Quantity</label>
                <vs-input :success="
                  !errors.first('countedQuality') &&
                  drugbookData.countedQuality != ''
                " val-icon-success="done" val-icon-danger="error"
                  :danger="errors.first('countedQuality') ? true : false" v-validate="{
                    required: true,
                    numeric: true,
                    min_value: 1,
                  }" type="number" name="countedQuality" data-vv-as="countedQuality" class="w-full"
                  :danger-text="errors.first('countedQuality')" v-model="drugbookData.countedQuality" />
              </div>
              <div class="w-full my-5">
                <label>Notes</label>
                <vs-textarea :class="errors.first('notes') ? 'customTextError' : ''" v-validate="{
                  required: true,
                }" name="notes" data-vv-as="notes" class="w-full" v-model="drugbookData.notes" />
                <div v-if="errors.first('notes')"
                  class="con-text-validation span-text-validation-danger vs-input--text-validation-span text-xs">
                  <span class="span-text-validation">Notes are mandatory for products with variances</span>
                </div>
              </div>
              <div class="my-5">
                <div class="my-3 flex justify-between text-sm font-medium">
                  <label style="color: #074230">Drugbook Balance:</label>
                  <label style="color: #074230">{{ drugbookData.drugBookBalance
                  }}{{
    drugbookData.unit === "ml" ? drugbookData.unit : "u"
}}</label>
                </div>
                <div class="my-3 flex justify-between text-sm font-medium">
                  <label style="color: #074230">Variance:</label>
                  <label style="color: #ea5455">{{
                      drugbookData.drugBookBalance > drugbookData.countedQuality
                        ? `-${drugbookData.drugBookBalance -
                        drugbookData.countedQuality
                        }`
                        : `+${Math.abs(
                          drugbookData.drugBookBalance -
                          drugbookData.countedQuality
                        )}`
                  }}{{
    drugbookData.unit === "ml" ? drugbookData.unit : "u"
}}</label>
                </div>
              </div>
              <div class="flex">
                <div class="w-1/2 px-2">
                  <vs-button class="w-full" @click="onCancel">Cancel</vs-button>
                </div>
                <div class="w-1/2 px-2">
                  <vs-button class="w-full" @click="onSave">Save</vs-button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { Validator } from "vee-validate";
import { mapActions } from "vuex";

const dict = {
  custom: {
    countedQuality: {
      required: "Please enter a valid amount",
      min_value: "Please enter a valid amount",
    },
    notes: {
      required: "Notes are mandatory for products with variances.",
    },
  },
};

Validator.localize("en", dict);
export default {
  // props: ["isComplianceCheck", "complianceDrugbook"],
  props: {
    complianceDrugbook: {
      type: Array,
      default: () => [],
    },
    isComplianceCheck: {
      type: Boolean,
    },
    maxHeight: {
      type: String,
      default: "550px",
    },
  },
  data() {
    return {
      totalDocs: 0,
      popupActive: false,
      drugbookData: {},
    };
  },
  methods: {
    ...mapActions("monthlyCompliance", [
      "updateStockTakeProduct",
      "insertStockTakeProduct",
    ]),
    toggleModal(data) {
      // this.drugbookData = {
      //   countedQuality: data.countedQuality,
      //   drugBookBalance: data.drugBookBalance,
      //   productName: data.productName,
      //   variance: data.variance,
      //   productId: data.productId,
      //   notes: data.notes,
      //   unit: data.unit,
      // }
      this.drugbookData = { ...data };
      this.popupActive = !this.popupActive;
    },
    onCloseModal() {
      this.popupActive = false;
    },
    onCancel() {
      this.popupActive = false;
      this.drugbookData = {};
      this.$emit("fetchStocktake");
      this.$validator.reset();
    },
    async onSave() {
      this.$vs.loading();
      let isValid = await this.$validator.validate();
      if (isValid) {
        try {
          console.log(this.drugbookData.fromDrugStocktake);
          if (this.drugbookData.fromDrugStocktake) {
            const payload = {
              notes: this.drugbookData.notes,
              countedQuality: this.drugbookData.countedQuality,
            };
            const res = await this.updateStockTakeProduct({
              payload: payload,
              complianceId:
                this.$route.name === "OrgOwnerComplianceStepImageUpload"
                  ? this.$route.params.clinicId
                  : this.$route.params.complianceId,
              productId: this.drugbookData.productId,
            });
            if (res.statusText === "OK") {
              this.$vs.notify({
                title: "Success",
                text: "Succesfully updated stocktake",
                color: "success",
              });
              this.popupActive = false;
              this.drugbookData = {};
              this.$emit("fetchStocktake");
              this.$validator.reset();
            } else {
              this.$vs.notify({
                title: "Failed",
                text: "Failed to update stocktake. Try again!",
                color: "danger",
              });
            }
          } else {
            let payload = {
              complianceId: this.$route.params.clinicId,
              productId: this.drugbookData.productId,
              drugBookBalance: this.drugbookData.drugBookBalance,
              countedQuality: this.drugbookData.countedQuality,
              unit: this.drugbookData.unit,
              notes: this.drugbookData.notes,
              variance:
                this.drugbookData.drugBookBalance -
                this.drugbookData.countedQuality,
            };

            let res = await this.insertStockTakeProduct(payload);

            if (res.statusText === "OK") {
              this.$vs.notify({
                title: "Success",
                text: "Succesfully created stocktake",
                color: "success",
              });
              this.popupActive = false;
              this.drugbookData = {};
              this.$emit("fetchStocktake");
              this.$validator.reset();
            } else {
              this.$vs.notify({
                title: "Failed",
                text: "Failed to create stocktake. Try again!",
                color: "danger",
              });
            }
          }
        } catch (e) {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Failed",
            text: "Failed to create stocktake. Try again!",
            color: "danger",
          });
        }
      }
      this.$vs.loading.close();
    },
  },
};
</script>

<style>
.vs-popup--content {
  margin-left: 5px;
}

.con-vs-popup .vs-popup {
  width: 450px !important;
}

.customTextError {
  border: 1px solid red !important;
}
</style>

