<template>
  <div>
    <div class="hidden md:block">
      <form autocomplete="off" @submit.prevent>
        <div>
          <div class="w-full my-5">
            <label>Drug</label>
            <vs-input :success="
              !errors.first('productName') &&
              selectedStockTake.productName != ''
            " val-icon-success="done" val-icon-danger="error" :danger="errors.first('productName') ? true : false"
              v-validate="'required|max:150'" name="productName" data-vv-as="productName" class="w-full"
              :danger-text="errors.first('productName')" v-model="selectedStockTake.productName" :disabled="true" />
          </div>
          <div class="w-full my-5">
            <label>Counted Quantity</label>
            <vs-input :success="
              !errors.first('countedQuality') &&
              selectedStockTake.countedQuality != ''
            " val-icon-success="done" val-icon-danger="error"
              :danger="errors.first('countedQuality') ? true : false" v-validate="{
                required: true,
                decimal: true
              }" type="text" name="countedQuality" data-vv-as="countedQuality" class="w-full"
              :danger-text="errors.first('countedQuality')" v-model="selectedStockTake.countedQuality" />
          </div>
          <div class="w-full my-5">
            <label>Notes</label>
            <vs-textarea :class="errors.first('notes') ? 'customTextError' : ''" v-validate="{
              required: true,
            }" name="notes" data-vv-as="notes" class="w-full" v-model="selectedStockTake.notes" />
            <div v-if="errors.first('notes')"
              class="con-text-validation span-text-validation-danger vs-input--text-validation-span text-xs">
              <span class="span-text-validation">Notes are mandatory for products with variances</span>
            </div>
          </div>
          <div class="my-5">
            <div class="my-3 flex justify-between text-sm font-medium">
              <label style="color: #074230">Drugbook Balance:</label>
              <label style="color: #074230">{{ selectedStockTake.drugBookBalance
              }}{{
    selectedStockTake.unit === "ml" ? selectedStockTake.unit : "u"
}}</label>
            </div>
            <div class="my-3 flex justify-between text-sm font-medium">
              <label style="color: #074230">Variance:</label>
              <label style="color: #ea5455">{{
                  selectedStockTake.drugBookBalance > selectedStockTake.countedQuality
                    ? `-${selectedStockTake.drugBookBalance -
                    selectedStockTake.countedQuality
                    }`
                    : `+${Math.abs(
                      selectedStockTake.drugBookBalance -
                      selectedStockTake.countedQuality
                    )}`
              }}{{
    selectedStockTake.unit === "ml" ? selectedStockTake.unit : "u"
}}</label>
            </div>
          </div>
          <div class="flex">
            <div class="w-1/2 px-2">
              <vs-button class="w-full" @click="onCancel">Cancel</vs-button>
            </div>
            <div class="w-1/2 px-2">
              <vs-button class="w-full" @click="onSave">Save</vs-button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class=" md:hidden">
      <div class="flex flex-col justify-between" style="min-height: 75vh">
        <div class="flex flex-col">
          <div class=" flex justify-between">
            <div>
              <p class="font-semibold text-xl customNameColor">
                {{ selectedStockTake.productName }}
              </p>
              <p class="py-2">
                {{ `${selectedStockTake.unit}` }}
              </p>
            </div>
            <div @click="onCancel">
              <vs-icon class=" cursor-pointer" icon="close" size="medium" />
            </div>
          </div>
          <vs-divider />
          <p class="py-2">Counted Quantity</p>
          <div class="w-full flex flex-row increment-buttons rounded-big-screen pt-0 py-0 border-solid">
            <div class=" flex-1 flex justify-center items-center text-4xl text-primary">
              <div @click="lessQuantity" class="cursor-pointer">-</div>
            </div>
            <div class="flex-1 flex justify-center items-center text-lg">
              <input type="number" class="text-center text-lg border-0 bg-transparent w-full"
                v-model.number="selectedStockTake.countedQuality" />
            </div>
            <div class="flex-1 flex justify-center items-center text-4xl text-primary">
              <div @click="addQuantity" class="cursor-pointer">+</div>
            </div>
          </div>
          <vs-divider></vs-divider>
          <div style="display: flex; justify-content: space-between">
            <p class="py-2 text-sm font-medium leading-6 not-italic inline-block" style="color: #000000">
              Drug Balance
            </p>
            <p class="py-2 text-sm font-medium leading-6 not-italic inline-block" style="color: #000000">
              {{ selectedStockTake.drugBookBalance
              }}{{
    selectedStockTake.unit === "ml" ? selectedStockTake.unit : "u"
}}
              <!-- ${
                      selectedStockTake.unit.toLowerCase() === "ml"
                        ? "ml"
                        : "u"
                    } -->
            </p>
          </div>
          <div style="display: flex; justify-content: space-between">
            <p class="py-2 text-sm font-medium leading-6 not-italic inline-block" style="color: #ea5455">
              Variance
            </p>
            <p class="py-2 text-sm font-medium leading-6 not-italic inline-block" style="color: #ea5455">
              {{
                  selectedStockTake.drugBookBalance > selectedStockTake.countedQuality
                    ? `-${selectedStockTake.drugBookBalance -
                    selectedStockTake.countedQuality
                    }`
                    : `+${Math.abs(
                      selectedStockTake.drugBookBalance -
                      selectedStockTake.countedQuality
                    )}`
              }}{{
    selectedStockTake.unit === "ml" ? selectedStockTake.unit : "u"
}}
              <!-- ${
                      selectedStockTake.unit.toLowerCase() === "ml"
                        ? "ml"
                        : "u"
                    } -->
            </p>
          </div>
          <vs-divider></vs-divider>
          <p class="py-2">Notes</p>
          <div class="w-full flex flex-col rounded-bl rounded-big-screen pt-0 py-0" style="border">
            <vs-textarea id="notesHeight" :class="
              errors.first('notes')
                ? 'customTextError'
                : ''
            " class="py-0 my-0 mb-2" name="notes" v-model="selectedStockTake.notes" />

            <div v-if="errors.first('notes') ? true : false"
              class="con-text-validation span-text-validation-danger vs-input--text-validation-span text-xs">
              <span class="span-text-validation">Notes are mandatory for products with variances</span>
            </div>
          </div>
        </div>
        <div class="">
          <vs-button class="w-full" @click="onSave">Save</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { Validator } from "vee-validate";
import { mapActions } from "vuex";

const dict = {
  custom: {
    countedQuality: {
      required: "Please enter a valid amount",
      // min_value: "Please enter a valid amount",
      decimal: "Please enter a valid amount"
    },
    notes: {
      required: "Notes are mandatory for products with variances.",
    },
  },
};

Validator.localize("en", dict);
export default {
  // props: ["isComplianceCheck", "complianceDrugbook"],
  props: {
    selectedStockTake: {
      type: Object,
      default: () => { },
    },
    popupActive: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      totalDocs: 0,
      isMobileView: null,
    };
  },
  methods: {
    ...mapActions("monthlyCompliance", [
      "updateStockTakeProduct",
      "insertStockTakeProduct",
    ]),
    addQuantity() {
      // if (
      //   this. selectedStockTake.countedQuality ==
      //   this. selectedStockTake.drugBookBalance
      // )
      //   return;

      this.selectedStockTake.countedQuality++;
      this.selectedStockTake.variance =
        this.selectedStockTake.drugBookBalance -
        this.selectedStockTake.countedQuality;
    },
    lessQuantity() {
      if (this.selectedStockTake.countedQuality == 0) return;
      this.selectedStockTake.countedQuality--;
      this.selectedStockTake.variance =
        this.selectedStockTake.drugBookBalance -
        this.selectedStockTake.countedQuality;
    },
    onCancel() {
      this.$validator.reset();
      this.$emit("onClose");
    },
    async onSave() {
      this.$vs.loading();
      let isValid = await this.$validator.validate();
      if (isValid) {
        try {
          if (this.selectedStockTake.fromDrugStocktake) {
            const payload = {
              notes: this.selectedStockTake.notes,
              countedQuality: this.selectedStockTake.countedQuality,
            };
            const res = await this.updateStockTakeProduct({
              payload: payload,
              complianceId: this.$route.params.clinicId,
              productId: this.selectedStockTake.productId,
            });
            if (res.statusText === "OK") {
              this.$vs.notify({
                title: "Success",
                text: "Succesfully updated stocktake",
                color: "success",
              });
              this.selectedStockTake = {};
              this.$emit("fetchStocktake");
              this.$emit("onClose");
              this.$validator.reset();
            } else {
              this.$vs.notify({
                title: "Failed",
                text: "Failed to update stocktake. Try again!",
                color: "danger",
              });
            }
          } else {
            let payload = {
              complianceId: this.$route.params.clinicId,
              productId: this.selectedStockTake.productId,
              drugBookBalance: this.selectedStockTake.drugBookBalance,
              countedQuality: this.selectedStockTake.countedQuality,
              unit: this.selectedStockTake.unit,
              notes: this.selectedStockTake.notes,
              variance:
                this.selectedStockTake.drugBookBalance -
                this.selectedStockTake.countedQuality,
            };

            let res = await this.insertStockTakeProduct(payload);

            if (res.statusText === "OK") {
              this.$vs.notify({
                title: "Success",
                text: "Succesfully created stocktake",
                color: "success",
              });
              // this.$emit("onEdit", false);
              this.$emit("onClose");
              this.selectedStockTake = {};
              this.$emit("fetchStocktake");
              this.$validator.reset();
            } else {
              this.$vs.notify({
                title: "Failed",
                text: "Failed to create stocktake. Try again!",
                color: "danger",
              });
            }
          }
        } catch (e) {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Failed",
            text: "Failed to create stocktake. Try again!",
            color: "danger",
          });
        }
      }
      this.$vs.loading.close();
    },
  },
  watch: {
    popupActive: function (newVal, oldVal) {
      if (!newVal) {
        this.onCancel()
      }
    }
  }
};
</script>
  
<style>
.vs-popup--content {
  margin-left: 5px;
}

.con-vs-popup .vs-popup {
  width: 450px !important;
}

.customTextError {
  border: 1px solid red !important;
}

/* .mobileForm {
    min-height: 40vh;
  } */
</style>
  

